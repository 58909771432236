import React, { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";

import { ReactComponent as CloseMenu } from "../../../images/components/Navbar/x.svg";
import { ReactComponent as MenuIcon } from "../../../images/components/Navbar/menu.svg";
import { ReactComponent as Logo } from "../../../images/universal/logos/white_logo_orange_arrows.svg";
// COMMENT OUT FOR SURVEY BANNER
// import wavy_bottom from "../../../images/components/Navbar/wavy_bottom.svg";

import "./Navbar.css";

function Navbar() {

  	const [click, setClick] = useState(false);
	const handleClick = () => setClick(!click);
	const closeMobileMenu = () => setClick(false);
	const location = useLocation();
	let pathname = location.pathname.toLowerCase();

	const [showSurveyBanner, setShowSurveyBanner] = useState(true);
    const dismissSurveyBanner = () => setShowSurveyBanner(false);

	if ((pathname.includes("christmas") || pathname.includes("summer")) && !(pathname.includes("blog"))){
		return null
	} else {
		// COMMENT OUT FOR SURVEY BANNER
		// window.onscroll = function() {
		// 	var currentScrollPos = window.scrollY;
		// 	if (currentScrollPos <= 60) {
		// 		document.getElementById("wavy-bottom").style.top = "79px";
		// 	} else {
		// 		document.getElementById("wavy-bottom").style.top = "0";
		// 	}
		// }
		return (
			<div className="nav-wrapper">
				<div className="navbar-content-wrapper">
					<NavLink to="/" aria-label="Home">
						<Logo className="logo" />
					</NavLink>

					<nav>
						{/* Either show the mobile menu or the desktop menu */}
						<div className={click ? "nav-options active" : "nav-options"}>
							<NavLink to="/about" onClick={closeMobileMenu} className="option navbar-link">About</NavLink>
							<NavLink to="/contact" className="option navbar-link" onClick={closeMobileMenu}>Contact us</NavLink>
							<div>
								{click ? 
									<div className="navbar-buttons">
										<NavLink to="/get-started" className="option navbar-button" onClick={closeMobileMenu}><button className="btn_orange_fill btn_nav">Get started</button></NavLink>
										<NavLink to="/teachers" className="option right-option navbar-button" onClick={closeMobileMenu}><button className="btn_orange_outline btn_nav">Info for teachers</button></NavLink>
									</div> : 
									<div className="navbar-buttons">
										<NavLink to="/get-started" className="option navbar-button" onClick={closeMobileMenu}><button className="btn_orange_fill btn_nav">Get started</button></NavLink>
										<NavLink to="/teachers" className="option right-option navbar-button" onClick={closeMobileMenu}><button className="btn_orange_outline btn_nav">Info for teachers</button></NavLink>
									</div>
								}
							</div>
						</div>
					</nav>

					<div className="mobile-menu" onClick={handleClick}>
						{click ? (
							<CloseMenu className="menu-icon" />
						) : (
							<MenuIcon className="menu-icon" />
						)}
					</div>

				</div>
				{/* COMMENT OUT FOR SURVEY BANNER */}
				{/* <div className="wavy_bottom_wrapper" id='wavy_bottom_wrapper'>
					<img className="wavybottom" id='wavy-bottom' src={wavy_bottom} alt="" />
				</div> */}

				{/* SURVEY BANNER */}
				<div className="news-banner-wrapper">
                    {showSurveyBanner ? 
                        <div className="news-banner-content">
                            <p className="news-banner-text">STUDENTS & TEACHERS: Please complete our short <a href="https://tally.so/r/wQxrYA" target="_blank" rel="noreferrer" className="white-colour-link">annual feedback survey</a> to help us improve Mission Encodeable.</p>
                            <CloseMenu className="dismiss-news-banner" onClick={dismissSurveyBanner}/>
                    </div> : <div></div>
                    }
                </div>

			</div>
		);
	};
};

export default Navbar;
