import "./Privacy.css"
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet';

function Privacy() {
    return (
        <div className="policy-wrapper">

            <Helmet>
                <title>Privacy policy | Mission Encodeable</title>
                <meta name="description" content="The Mission Encodeable privacy policy explains the what, how, and why of the information we collect when you visit our website." />
            </Helmet>

            <div className="privacy-fill-header">
                <div className="text-header">
                    <h1 className="darkgreen">Privacy policy</h1>
                    <p className="darkgreen">At Mission Encodeable, we are committed to protecting your privacy. Our privacy policy explains what information we collect, how we collect it, what we do with it and how we keep it secure.</p>
                </div>
            </div>

            <div className="policy-body">
                <h3>Who we are</h3>
                <p>Mission Encodeable is a student-led not-for-profit based in England, UK. Our mission is to inspire future computer scientists. We do this by creating free, creative tutorials that make coding accessible to students everywhere. These tutorials are available on our website, <a href="https://missionencodeable.com">missionencodeable.com</a>.</p>
                <p>Within the context of this policy, when we refer to 'we', 'us', or 'our', we're referring to Mission Encodeable.</p>

                <h3>What information we collect from you</h3>

                <h4>Information you provide to us directly</h4>
                <p>When you visit our website, you might be asked to provide some personal data. Our tutorials, and the vast majority of our resources for teachers, are available without us requiring any personal information from you. If you don't want to provide us with personal data you don't have to, but you may miss out on some of our services and resources.</p>

                <ul>
                    <li><strong>Contact form:</strong> Our contact form allows you to send us a message alongside your name and email address.</li>
                    <li><strong>Feedback forms:</strong> We have numerous feedback forms on our website, all of which are entirely voluntary. The responses we receive help us to better understand our users so that we can improve what we do. These forms do not ask for personal information from young people, and we inform them that their response will be deleted if they include any details which could identify them as an individual. We give teachers the option to provide their name, email address and the name of their school so that we can follow up with them on their comments if they'd like.</li>
                    <li><strong>Focus groups:</strong> We occasionally run online focus groups with students and teachers to gather feedback to improve Mission Encodeable. In order to sign up, we require participants' name, email address and school name, as well as parental consent if the participant is under 18. The meeting will be recorded; after a month, we'll delete it and anonymise the transcript.</li>
                    <li><strong>Requesting solution files:</strong> Teachers can request access to a comprehensive set of Python code files that offer solutions for all of the projects in our course. In order to request access to these files, we require teachers to provide their name, the name of their school, and their email address. So that we can verify that they are a teacher, we ask them to provide us with their school reception office's email so we can check, or a link to a page on their school's website which states that they are a teacher.</li>
                    <li><strong>Teacher newsletter:</strong> Teachers can sign up to a monthly newsletter which provides updates about Mission Encodeable. Any emails which look like they belong to students (such as those containing numbers), or emails which do not end in a professional domain name, will be deleted from our subscriber list. You can unsubscribe from our newsletter at any time by clicking the link in the footer of every newsletter we send.</li>
                    <li><strong>Demos:</strong> Teachers can request a video call with one of Mission Encodeable's founders to receive a short presentation and tour of the platform. In order to offer a demo, we require a name, email address and school name, as well as any optional comments. Teachers will be able to book a demo using a link we send them after we receive a request. It is not necessary to have a demo before using our tutorials with students.</li>
                </ul>

                <h4>Information we collect automatically</h4>
                <p>The majority of information we collect, we collect directly from you. When you visit our website, some data is collected and stored automatically. For example, the device you are using and the time of your visit. We also keep a record of what web pages you have looked at and how long you have looked at them. We may collect this information through the use of cookies or other tracking technologies.</p>

                <h4>Information we get from third parties</h4>
                <p>Sometimes we might collect personal data about you from other publicly available sources, such as your school's website. We use this information to supplement the personal data we already hold about you, and to validate the personal data you provide, for example, so that we can verify if you are a teacher if you request access to our solution files.</p>

                <h3>How we use the information we collect</h3>
                <ul>
                    <li>To respond to your queries</li>
                    <li>To improve our services, user experience and content</li>
                    <li>To send our newsletter to teachers who have consented</li>
                </ul>

                <h3>How we share your data</h3>
                <p>We do not share your data with third party marketers. There are times we may share your data with third parties to process your data on our behalf. We will only disclose your data to:</p>
                <ul>
                    <li>Third-party service providers to process and store user data. We may provide these vendors with personal data, to carry out the services they are performing for us. Third-party analytics services providers such as Google Analytics and Hotjar may set and access their own tracking technologies on your device.</li>
                    <li>Regulators, law enforcement bodies, government agencies, courts or other third parties where we think it's necessary to comply with applicable laws or regulations, or to exercise, establish or defend our legal rights.</li>
                    <li>If we have a safeguarding concern regarding a student and they supplied us with their details then we may contact their school or the local authority. This doesn't change the fact that in all other cases, we don't retain information from students which could identify them as individuals.</li>
                </ul>

                <h3>How long we store your data</h3>
                <p>The length of time we keep your personal data depends on what it is and whether we have an ongoing need to retain it. We do not keep your information for any longer than we need to in order to fulfil the purpose or purposes for which it was collected, or for any longer than is required by law, if that is longer.</p>

                <h4>Anonymised data</h4>
                <p>By definition, anonymised data is data that does not contain any information that can be used to identify an individual.</p>
                <p>Once we have anonymised data we are lawfully allowed to retain it for as long as we need it, with no formal justification.</p>
                <p>Anonymised data may be useful for analytical or statistical insight. We are most likely to retain anonymised data for research, analysis, and impact evaluation.</p>

                <h3>Security</h3>
                <p>Security is a priority for us when it comes to your personal data. We're committed to protecting your personal data and have appropriate technical and organisational measures in place to make sure that happens.</p>
                <p>You should be aware that the use of the internet is not entirely secure, and although we will do our best to protect your personal data, we cannot guarantee the security or integrity of any personal information that is transferred from you or to you via the internet. Any transmission is at your own risk. Once we have received your information, we will use strict procedures and security features such as encryption to try to prevent unauthorised access.</p>
                <p>If a security breach causes an unauthorised intrusion into our system that materially affects you, then Mission Encodeable will notify you as soon as possible and later report the action we took in response.</p>

                <h3>Your rights</h3>
                <p>Under data protection law, you have rights including:</p>
                <ul>
                    <li><strong>Your right of access:</strong> You have the right to ask us for copies of your personal information.</li>
                    <li><strong>Your right to rectification:</strong> You have the right to ask us to rectify personal information you think is inaccurate. You also have the right to ask us to complete information you think is incomplete.</li>
                    <li><strong>Your right to erasure:</strong> You have the right to ask us to erase your personal information in certain circumstances.</li>
                    <li><strong>Your right to restriction of processing:</strong> You have the right to ask us to restrict the processing of your personal information in certain circumstances.</li>
                    <li><strong>Your right to object to processing:</strong> You have the right to object to the processing of your personal information in certain circumstances.</li>
                    <li><strong>Your right to data portability:</strong> You have the right to ask that we transfer the personal information you gave us to another organisation, or to you, in certain circumstances.</li>
                </ul>
                <p>You are not required to pay any charge for exercising your rights. If you make a request, we have one month to respond to you. Please contact us via our contact page if you wish to make a request.</p>

                <h3>On what legal basis we use your information</h3>
                <ul>
                    <li>Where it is within our legitimate interests to process your information to further our mission to inspire future computer scientists. We know that under this lawful basis for processing, we have a heightened responsibility to keep your interests central and to make sure that your rights as a data subject override our legitimate interests.</li>
                    <li>Where we need to comply with a legal obligation.</li>
                </ul>

                <h3>Contacting us or making a complaint</h3>
                <p>If you are unhappy about the way we have processed your personal information for any reason, please contact us in the first instance to see if we can resolve your complaint. If you are still unhappy, you can contact the Information Commissioner's Office as the statutory body which oversees data protection law – <a href="https://ico.org.uk/concerns/handling/">https://ico.org.uk/concerns/handling/</a>.</p>

                <h3>Changes to this policy</h3>
                <p>We may update this privacy policy from time to time to ensure it remains fully accurate. You should check this page occasionally to ensure you are happy with any changes.</p>
                <p><strong>Updated December 2024</strong></p>

            </div>
        </div>
    )
}

export default Privacy